import ApiClient from './ApiClient';
import {
CreateNaturalHolder$Request,
UpdateStockClass$Request$UploadedDocumentRequest,
CancelSignAction$Response,
CreateHolderTransfer$Request$CryptoCurrency,
CreateIssuance$Request$UploadedDocumentRequest,
PollSignActionStatus$Request,
MyAccountStockListDto,
DeleteStockClass$Response,
CreateIssuance$Request$AccountStock,
CreateBulkHolder$Request$LegalEntity,
CreateIssuance$Request$PartlyPaid,
SaveStockClass$Request$StockSeriesRequest,
CreateBulkTransfer$Request$CreateTransfer$TransferAction,
Logout$Response,
GetLedgerCryptoWallet$Response,
TransferHistoricImportDraftId,
CreateIssuance$Request$Payment,
StockClassDto,
CredentialType,
CreateHolderTransfer$Request$UploadedDocumentRequest,
WhoAmI$Response$CurrentHolder,
AddLedgerAdmin$Response,
AcceptNotification$Response,
SaveStockClass$Response,
VerifyPhoneNumber$Request,
EditHolderTransfer$Response,
VerifyPhoneNumber$Response,
CreateIssuance$Request$NonCash,
IssuanceId,
CreateBulkTransfer$Request$CreateTransfer,
PollRecoveryBySmsStatus$Response,
GetPendingLedgerAdminEmailList$Request,
SaveLedgerCryptoWallet$Response,
CreateBulkIssuance$Request,
CreateBulkIssuance$Request$CryptoCurrency,
GetAccountList$Request,
CreateBulkIssuance$Request$AccountStock,
CreateHolderTransfer$Request,
DeleteLedgerHistoricImportDraftTransfer$Response,
GetTransferList$Response,
DeleteDraft$Response,
SignLedgerContract$Request,
UpdateStockSeries$Request,
AddressRequest,
CreateBulkHolder$Request,
CreateRetirementTransfer$Request$UploadedDocumentRequest,
GetUserCredentials$Response,
CreateStockSeries$Request$UploadedDocumentRequest,
GetMyStockList$Response,
CredentialId,
StockSeriesListDto,
ChangePassword$Response,
CreateRetirementTransfer$Request,
SaveLedgerDetails$Request,
GenerateContractFile$Request$Type,
ChangeCredential$Request,
SaveStockClass$Request,
ContractListDto,
SaveUserOnboardingDetails$Request$UploadedDocumentRequest,
LedgerNotificationId,
StockListDto$StockClassTotalsDto,
CancelTransfer$Request$UploadedDocumentRequest,
ReTriggerCredentialVerification$Request,
StockType,
LedgerBalanceDto,
GetDraft$Request,
WhoAmI$Response,
CancelTransfer$Request,
GetLedgerBasicDetails$Response$Treasury,
GetNaturalPersonHolderDetails$Request,
AcceptTransfer$Response,
RecoverBySms$Response,
CancelSignAction$Request,
GetLedgerDocuments$Response,
GetMyAccounts$Response,
RevokePendingLedgerAdminEmail$Request,
UpdateNaturalPersonHolderDetails$Response,
ConfirmBulkStockBalanceImport$Request,
GetDashboard$Request,
CreateIssuance$Request$AmountPaid,
GetLedgerDocuments$LedgerDocument,
CreateTransfer$Request$UploadedDocumentRequest,
CreateStockSeries$Request,
Logout$Request,
PollSignActionStatus$Response$Error,
ConfirmStockClass$Request$ConfirmStockClassRequest,
UpdateStockClassDetails$Response,
AccountDto,
CreateBulkTransfer$Request,
DeclineTransfer$Response,
AccountId,
LedgerAdminUserDto,
GetStockList$Response,
GetLedgerDocuments$Request,
GetLedgerBasicDetails$Response$LedgerAdminUserDto,
PollRecoveryBySmsStatus$Request,
SaveCryptoTransferPayment$Response$Status,
SaveHolderDocuments$Request,
VerifyWithSecondaryMethod$Request,
GetUserDetails$Response,
UpdateLedgerDetails$Request$UploadedDocumentRequest,
GetAccountStockList$Response,
ProfileDto,
CreateBulkTransfer$Request$CreateTransfer$Payment,
SignActionId,
SearchDraftAccount$Request,
CreateBulkStockBalance$Request,
GetDashboard$Response$Progress,
PollSignActionStatus$Response,
CreatePassword$Request,
GetNaturalPersonHolderDetails$Response,
SaveDraft$Response,
ChangePassword$Request,
RecoverBySms$Request,
GetTransferDetails$Request,
CreatePassword$Response,
HolderUserWithVerifiedCredentialsDto,
GetNotifications$Response,
UpdateLedgerDetails$Request,
UpdateLegalEntityHolderDetails$Response,
LedgerId,
ConfirmStockClass$Request,
UpdateNaturalPersonHolderDetails$Request,
AddLegalEntityCompanyDetails$Request,
CreateBulkStockBalance$Response,
GetLedgerHistoricImportDraft$Response$StockClass,
CreateStockClass$Request,
GetNotifications$Request,
GetTransferRestrictions$Response,
GetTransferDetails$Response$Cancellation,
EditHolderTransfer$Request,
GetMyStockList$Request,
LedgerWithProfilesDto,
CreateNaturalHolder$Response,
Login$Request,
CurrencyType,
CreateBulkIssuance$Request$Payment,
UpdateLegalEntityHolderDetails$Request,
ResendHolderEmail$Request,
SendAppDownloadInfo$Response,
RevokePendingLedgerAdminEmail$Response,
StartRecoverBySms$Response,
SaveLedgerHistoricImportDraftTransfer$Request$InformativeRow,
GetLedgerStockBalance$Response,
ReactivateProfile$Request,
CreateStockClass$Request$UploadedDocumentRequest,
EditHolderTransfer$Request$ToRequest,
GetAvailableProfiles$Response,
RemoveCredential$Response,
PendingLedgerAdminEmailId,
GetTransferList$Request,
ReplaceVerifiedApp$Request,
DeleteStockClass$Request,
SaveHolderDocuments$Request$UploadedDocumentRequest,
SearchAccountWithStock$Response,
AddLegalEntityCompanyDetails$Response,
LedgerCompanyType,
GetAccountStockList$Request,
RemoveCredential$Request,
UploadedDocumentDto,
NonCashType,
GetDraft$Response,
CreateTransfer$Response,
SaveLedgerHistoricImportDraftTransfer$Request,
AccessRecoveryBySmsStatusType,
UpdateLegalEntityCompanyDetails$Response,
GetLedgerBasicDetails$Response$Company,
DocumentType,
GetLedgerHistoricImportDraft$Request,
CreateTransfer$Request,
AcceptNotification$Request,
MyPendingTransferListDto,
Login$Response,
CredentialDto,
GetHolderDocuments$Request,
SaveLedgerHistoricImportDraftTransfer$Request$UploadedDocumentRequest,
SelectProfile$Response,
PollSignActionStatus$Status,
GetTransferRestrictions$Request,
PollSignActionStatus$SigningType,
ProfileId,
GetDashboard$Response$HistoricImport,
UpdateLedgerDetails$Response,
SaveLedgerDetails$Response,
UpdateStockClassDetails$Request,
GetTransferDetails$Response$Participant,
GetLegalEntityHolderDetails$Response,
MyAccountStockListDto$StockItem,
CreateBulkTransfer$Request$UploadedDocumentRequest,
CreateIssuance$Request$TotalAmountToBePaid,
ConfirmLedgerHistoricImportDraft$Request,
HolderDto,
VerifyWithSecondaryMethod$Response,
GetPendingLedgerAdminEmailList$Response,
CreateIssuance$Request,
StockClassId,
CancelTransfer$Response,
SendAppDownloadInfo$Request,
HolderId,
GetLedgerUserList$Request,
CreateBulkTransfer$Request$CreateTransfer$CurrencyType,
UpdateUserDetails$Request,
SelectProfile$Request,
AddCredential$Request,
HolderType,
DraftDto,
CreateBulkHolder$Request$Holder,
Login$LoginStatus,
GetNotifications$Response$Notification,
DeclineNotification$Response,
GetTransferDetails$Response,
StockListDto,
CreateBulkHolder$Request$NaturalPerson,
StartResetPassword$Response,
SearchAccountWithStock$Request,
CreateBulkIssuance$Response,
GetLegalEntityCompanyDetails$Request,
AddMyselfAsHolder$Response,
WhoAmI$Request,
GetDashboard$Response,
GetContractList$Response,
CreateHolderTransfer$Request$ToRequest,
CreateBulkTransfer$Response,
CreateIssuance$Request$FullyPaid,
DraftId,
WhoAmI$Response$CurrentProfile,
UpdateStockClass$Request,
SearchAccount$Request,
DeclineNotification$Request,
GetMyPendingTransferList$Response,
SearchAccount$Response,
TransferId,
ReactivateProfile$Response,
CreateBulkIssuance$Request$UploadedDocumentRequest,
ValidatePhone$Request,
GetLegalEntityHolderDetails$Request,
UpdateLegalEntityCompanyDetails$Request,
LedgerBalanceDto$AccountBalanceDto,
SaveUserOnboardingDetails$Response,
StockSeriesListDto$Reason,
AccountStockListDto,
GetContractList$Request,
CreateLegalHolder$Response$MismatchProblem,
CreateStockClass$Response,
CreateRetirementTransfer$Response,
CreateBulkStockBalance$Request$UploadedDocumentRequest,
CreateHolderTransfer$Response,
GetHolderDocuments$HolderDocument,
SaveCryptoTransferPayment$Request,
SaveTransferRestrictions$Response,
GetUserCryptoWallet$Response,
RecoverBySms$Status,
SaveStockClass$Request$StockClassRequest,
ChangeCredential$Response,
StartRecoverBySms$Request,
GetLedgerCryptoWallet$Request,
CreateNaturalHolder$Response$MismatchProblem,
LedgerBalanceDto$AccountBalanceDto$AccountStockBalanceDto,
GetUserCryptoWallet$Request,
GetNotifications$Response$Content,
CreateBulkHolder$Response,
GetLedgerDetails$Request,
GetLedgerBasicDetails$Response,
GetLedgerHistoricImportDraft$Response$StockHolder,
GetDashboard$Response$Ledger,
UpdateStockClass$Response,
GetMyPendingTransferList$Request,
GetLedgerStockBalance$Request,
GetAvailableProfiles$Request,
SearchDraftAccount$Response,
ConfirmLedgerHistoricImportDraft$Response,
SensitiveString,
GetAccountList$Response,
DeleteDraft$Request,
TransferListDto$Participant,
GetMyAccounts$Request,
GetLedgerHistoricImportDraft$Response,
AddressId,
GetLedgerDetails$Response,
ProfileRole,
SaveLedgerCryptoWallet$Request,
DeactivateProfile$Request,
ValidatePhone$Response,
GetHolderDocuments$Response,
CreateStockSeries$Response,
GenerateContractFile$Response,
GetUserDetails$Request,
SaveLedgerHistoricImportDraftTransfer$Response,
MyPendingTransferListDto$AccountDto,
AddCredential$Response,
GetLedgerBasicDetails$Request,
AddressDto,
GetLegalEntityCompanyDetails$Response,
SaveCryptoTransferPayment$Response,
GetTransferDetails$Response$PendingTransferAttributes,
StartResetPassword$Request,
HolderCredential,
ResetPassword$Response,
SaveUserCryptoWallet$Request,
UpdateStockSeries$Request$UploadedDocumentRequest,
CreateBulkIssuance$Request$CurrencyType,
SignLedgerContract$Response,
ResendHolderEmail$Response,
GetMyAccounts$Account,
SaveHolderDocuments$Response,
CreateIssuance$Response,
GetStockList$Request,
DeclineTransfer$Request,
AddLedgerAdmin$Request,
TransferListDto,
SaveUserCryptoWallet$Response,
ResetPassword$Request,
ConfirmStockClass$Response,
SaveDraft$Request,
PendingLedgerAdminEmailDto,
StockListDto$Reason,
LedgerState,
GenerateContractFile$Request,
AcceptTransfer$Request,
StockSeriesListDto$StockSeriesTotalsDto,
GetLedgerUserList$Response,
GetTransferDetails$Response$StockClass,
TransferStatusType,
CreateLegalHolder$Response,
GetLedgerHistoricImportDraft$Response$AccountStock,
UpdateUserDetails$Request$UploadedDocumentRequest,
GetUserCredentials$Request,
UpdateUserDetails$Response,
CreateBulkStockBalance$Request$HolderBalance,
CreateBulkTransfer$Request$CreateTransfer$CryptoCurrency,
AddMyselfAsHolder$Request,
SaveUserOnboardingDetails$Request,
GetLedgerDocuments$DocumentStockClass,
GetNotifications$Response$Type,
DeactivateProfile$Response,
SignActionVerificationCode,
ReTriggerCredentialVerification$Response,
CreateBulkHolder$Response$Holder,
ContractId,
GetTransferDetails$Response$Payment,
DeleteLedgerHistoricImportDraftTransfer$Request,
UserId,
SaveLedgerDetails$Request$UploadedDocumentRequest,
SaveTransferRestrictions$Request,
ConfirmBulkStockBalanceImport$Response,
ReplaceVerifiedApp$Response,
UpdateStockSeries$Response,
GetTransferDetails$Response$CreatedByAdmin,
CreateLegalHolder$Request,
} from './api.types'
import { AxiosInstance, AxiosResponse } from 'axios';

export class ApiService {

    public ledger: LedgerApiService;
    public auth: AuthApiService;
    public user: UserApiService;

    constructor() {
        this.ledger = new LedgerApiService(ApiClient);
        this.auth = new AuthApiService(ApiClient);
        this.user = new UserApiService(ApiClient);
    }

}

export class LedgerApiService {
    constructor(private api: AxiosInstance) {}

    acceptNotification = async (request: AcceptNotification$Request): Promise<AxiosResponse<AcceptNotification$Response>> => {
        return this.api.post('acceptNotification', request);
    }
    confirmLedgerHistoricImportDraft = async (): Promise<AxiosResponse<ConfirmLedgerHistoricImportDraft$Response>> => {
        return this.api.post('confirmLedgerHistoricImportDraft');
    }
    declineNotification = async (request: DeclineNotification$Request): Promise<AxiosResponse<DeclineNotification$Response>> => {
        return this.api.post('declineNotification', request);
    }
    deleteDraft = async (request: DeleteDraft$Request): Promise<AxiosResponse<DeleteDraft$Response>> => {
        return this.api.post('deleteDraft', request);
    }
    getDashboard = async (): Promise<AxiosResponse<GetDashboard$Response>> => {
        return this.api.post('getDashboard');
    }
    getDraft = async (request: GetDraft$Request): Promise<AxiosResponse<GetDraft$Response>> => {
        return this.api.post('getDraft', request);
    }
    getLedgerBasicDetails = async (): Promise<AxiosResponse<GetLedgerBasicDetails$Response>> => {
        return this.api.post('getLedgerBasicDetails');
    }
    getLedgerCryptoWallet = async (): Promise<AxiosResponse<GetLedgerCryptoWallet$Response>> => {
        return this.api.post('getLedgerCryptoWallet');
    }
    getLedgerDetails = async (): Promise<AxiosResponse<GetLedgerDetails$Response>> => {
        return this.api.post('getLedgerDetails');
    }
    getLedgerDocuments = async (): Promise<AxiosResponse<GetLedgerDocuments$Response>> => {
        return this.api.post('getLedgerDocuments');
    }
    getLedgerHistoricImportDraft = async (): Promise<AxiosResponse<GetLedgerHistoricImportDraft$Response>> => {
        return this.api.post('getLedgerHistoricImportDraft');
    }
    getLedgerUserList = async (): Promise<AxiosResponse<GetLedgerUserList$Response>> => {
        return this.api.post('getLedgerUserList');
    }
    getNotifications = async (): Promise<AxiosResponse<GetNotifications$Response>> => {
        return this.api.post('getNotifications');
    }
    getTransferRestrictions = async (): Promise<AxiosResponse<GetTransferRestrictions$Response>> => {
        return this.api.post('getTransferRestrictions');
    }
    saveDraft = async (request: SaveDraft$Request): Promise<AxiosResponse<SaveDraft$Response>> => {
        return this.api.post('saveDraft', request);
    }
    saveLedgerCryptoWallet = async (request: SaveLedgerCryptoWallet$Request): Promise<AxiosResponse<SaveLedgerCryptoWallet$Response>> => {
        return this.api.post('saveLedgerCryptoWallet', request);
    }
    saveLedgerDetails = async (request: SaveLedgerDetails$Request): Promise<AxiosResponse<SaveLedgerDetails$Response>> => {
        return this.api.post('saveLedgerDetails', request);
    }
    saveTransferRestrictions = async (request: SaveTransferRestrictions$Request): Promise<AxiosResponse<SaveTransferRestrictions$Response>> => {
        return this.api.post('saveTransferRestrictions', request);
    }
    updateLedgerDetails = async (request: UpdateLedgerDetails$Request): Promise<AxiosResponse<UpdateLedgerDetails$Response>> => {
        return this.api.post('updateLedgerDetails', request);
    }
    getAccountList = async (): Promise<AxiosResponse<GetAccountList$Response>> => {
        return this.api.post('getAccountList');
    }
    getMyAccounts = async (): Promise<AxiosResponse<GetMyAccounts$Response>> => {
        return this.api.post('getMyAccounts');
    }
    searchAccount = async (request: SearchAccount$Request): Promise<AxiosResponse<SearchAccount$Response>> => {
        return this.api.post('searchAccount', request);
    }
    searchAccountWithStock = async (request: SearchAccountWithStock$Request): Promise<AxiosResponse<SearchAccountWithStock$Response>> => {
        return this.api.post('searchAccountWithStock', request);
    }
    searchDraftAccount = async (request: SearchDraftAccount$Request): Promise<AxiosResponse<SearchDraftAccount$Response>> => {
        return this.api.post('searchDraftAccount', request);
    }
    addLedgerAdmin = async (request: AddLedgerAdmin$Request): Promise<AxiosResponse<AddLedgerAdmin$Response>> => {
        return this.api.post('addLedgerAdmin', request);
    }
    deactivateProfile = async (request: DeactivateProfile$Request): Promise<AxiosResponse<DeactivateProfile$Response>> => {
        return this.api.post('deactivateProfile', request);
    }
    getPendingLedgerAdminEmailList = async (): Promise<AxiosResponse<GetPendingLedgerAdminEmailList$Response>> => {
        return this.api.post('getPendingLedgerAdminEmailList');
    }
    reactivateProfile = async (request: ReactivateProfile$Request): Promise<AxiosResponse<ReactivateProfile$Response>> => {
        return this.api.post('reactivateProfile', request);
    }
    revokePendingLedgerAdminEmail = async (request: RevokePendingLedgerAdminEmail$Request): Promise<AxiosResponse<RevokePendingLedgerAdminEmail$Response>> => {
        return this.api.post('revokePendingLedgerAdminEmail', request);
    }
    generateContractFile = async (request: GenerateContractFile$Request): Promise<AxiosResponse<GenerateContractFile$Response>> => {
        return this.api.post('generateContractFile', request);
    }
    getContractList = async (): Promise<AxiosResponse<GetContractList$Response>> => {
        return this.api.post('getContractList');
    }
    signLedgerContract = async (request: SignLedgerContract$Request): Promise<AxiosResponse<SignLedgerContract$Response>> => {
        return this.api.post('signLedgerContract', request);
    }
    addLegalEntityCompanyDetails = async (request: AddLegalEntityCompanyDetails$Request): Promise<AxiosResponse<AddLegalEntityCompanyDetails$Response>> => {
        return this.api.post('addLegalEntityCompanyDetails', request);
    }
    addMyselfAsHolder = async (): Promise<AxiosResponse<AddMyselfAsHolder$Response>> => {
        return this.api.post('addMyselfAsHolder');
    }
    confirmBulkStockBalanceImport = async (): Promise<AxiosResponse<ConfirmBulkStockBalanceImport$Response>> => {
        return this.api.post('confirmBulkStockBalanceImport');
    }
    createBulkHolder = async (request: CreateBulkHolder$Request): Promise<AxiosResponse<CreateBulkHolder$Response>> => {
        return this.api.post('createBulkHolder', request);
    }
    createBulkStockBalance = async (request: CreateBulkStockBalance$Request): Promise<AxiosResponse<CreateBulkStockBalance$Response>> => {
        return this.api.post('createBulkStockBalance', request);
    }
    createLegalHolder = async (request: CreateLegalHolder$Request): Promise<AxiosResponse<CreateLegalHolder$Response>> => {
        return this.api.post('createLegalHolder', request);
    }
    createNaturalHolder = async (request: CreateNaturalHolder$Request): Promise<AxiosResponse<CreateNaturalHolder$Response>> => {
        return this.api.post('createNaturalHolder', request);
    }
    getHolderDocuments = async (request: GetHolderDocuments$Request): Promise<AxiosResponse<GetHolderDocuments$Response>> => {
        return this.api.post('getHolderDocuments', request);
    }
    getLegalEntityCompanyDetails = async (): Promise<AxiosResponse<GetLegalEntityCompanyDetails$Response>> => {
        return this.api.post('getLegalEntityCompanyDetails');
    }
    getLegalEntityHolderDetails = async (request: GetLegalEntityHolderDetails$Request): Promise<AxiosResponse<GetLegalEntityHolderDetails$Response>> => {
        return this.api.post('getLegalEntityHolderDetails', request);
    }
    getNaturalPersonHolderDetails = async (request: GetNaturalPersonHolderDetails$Request): Promise<AxiosResponse<GetNaturalPersonHolderDetails$Response>> => {
        return this.api.post('getNaturalPersonHolderDetails', request);
    }
    resendHolderEmail = async (request: ResendHolderEmail$Request): Promise<AxiosResponse<ResendHolderEmail$Response>> => {
        return this.api.post('resendHolderEmail', request);
    }
    saveHolderDocuments = async (request: SaveHolderDocuments$Request): Promise<AxiosResponse<SaveHolderDocuments$Response>> => {
        return this.api.post('saveHolderDocuments', request);
    }
    updateLegalEntityCompanyDetails = async (request: UpdateLegalEntityCompanyDetails$Request): Promise<AxiosResponse<UpdateLegalEntityCompanyDetails$Response>> => {
        return this.api.post('updateLegalEntityCompanyDetails', request);
    }
    updateLegalEntityHolderDetails = async (request: UpdateLegalEntityHolderDetails$Request): Promise<AxiosResponse<UpdateLegalEntityHolderDetails$Response>> => {
        return this.api.post('updateLegalEntityHolderDetails', request);
    }
    updateNaturalPersonHolderDetails = async (request: UpdateNaturalPersonHolderDetails$Request): Promise<AxiosResponse<UpdateNaturalPersonHolderDetails$Response>> => {
        return this.api.post('updateNaturalPersonHolderDetails', request);
    }
    confirmStockClass = async (request: ConfirmStockClass$Request): Promise<AxiosResponse<ConfirmStockClass$Response>> => {
        return this.api.post('confirmStockClass', request);
    }
    createStockClass = async (request: CreateStockClass$Request): Promise<AxiosResponse<CreateStockClass$Response>> => {
        return this.api.post('createStockClass', request);
    }
    createStockSeries = async (request: CreateStockSeries$Request): Promise<AxiosResponse<CreateStockSeries$Response>> => {
        return this.api.post('createStockSeries', request);
    }
    deleteStockClass = async (request: DeleteStockClass$Request): Promise<AxiosResponse<DeleteStockClass$Response>> => {
        return this.api.post('deleteStockClass', request);
    }
    getAccountStockList = async (request: GetAccountStockList$Request): Promise<AxiosResponse<GetAccountStockList$Response>> => {
        return this.api.post('getAccountStockList', request);
    }
    getLedgerStockBalance = async (request: GetLedgerStockBalance$Request): Promise<AxiosResponse<GetLedgerStockBalance$Response>> => {
        return this.api.post('getLedgerStockBalance', request);
    }
    getMyStockList = async (): Promise<AxiosResponse<GetMyStockList$Response>> => {
        return this.api.post('getMyStockList');
    }
    getStockList = async (): Promise<AxiosResponse<GetStockList$Response>> => {
        return this.api.post('getStockList');
    }
    saveStockClass = async (request: SaveStockClass$Request): Promise<AxiosResponse<SaveStockClass$Response>> => {
        return this.api.post('saveStockClass', request);
    }
    updateStockClass = async (request: UpdateStockClass$Request): Promise<AxiosResponse<UpdateStockClass$Response>> => {
        return this.api.post('updateStockClass', request);
    }
    updateStockClassDetails = async (request: UpdateStockClassDetails$Request): Promise<AxiosResponse<UpdateStockClassDetails$Response>> => {
        return this.api.post('updateStockClassDetails', request);
    }
    updateStockSeries = async (request: UpdateStockSeries$Request): Promise<AxiosResponse<UpdateStockSeries$Response>> => {
        return this.api.post('updateStockSeries', request);
    }
    acceptTransfer = async (request: AcceptTransfer$Request): Promise<AxiosResponse<AcceptTransfer$Response>> => {
        return this.api.post('acceptTransfer', request);
    }
    cancelTransfer = async (request: CancelTransfer$Request): Promise<AxiosResponse<CancelTransfer$Response>> => {
        return this.api.post('cancelTransfer', request);
    }
    createBulkIssuance = async (request: CreateBulkIssuance$Request): Promise<AxiosResponse<CreateBulkIssuance$Response>> => {
        return this.api.post('createBulkIssuance', request);
    }
    createBulkTransfer = async (request: CreateBulkTransfer$Request): Promise<AxiosResponse<CreateBulkTransfer$Response>> => {
        return this.api.post('createBulkTransfer', request);
    }
    createHolderTransfer = async (request: CreateHolderTransfer$Request): Promise<AxiosResponse<CreateHolderTransfer$Response>> => {
        return this.api.post('createHolderTransfer', request);
    }
    createIssuance = async (request: CreateIssuance$Request): Promise<AxiosResponse<CreateIssuance$Response>> => {
        return this.api.post('createIssuance', request);
    }
    createRetirementTransfer = async (request: CreateRetirementTransfer$Request): Promise<AxiosResponse<CreateRetirementTransfer$Response>> => {
        return this.api.post('createRetirementTransfer', request);
    }
    createTransfer = async (request: CreateTransfer$Request): Promise<AxiosResponse<CreateTransfer$Response>> => {
        return this.api.post('createTransfer', request);
    }
    declineTransfer = async (request: DeclineTransfer$Request): Promise<AxiosResponse<DeclineTransfer$Response>> => {
        return this.api.post('declineTransfer', request);
    }
    deleteLedgerHistoricImportDraftTransfer = async (request: DeleteLedgerHistoricImportDraftTransfer$Request): Promise<AxiosResponse<DeleteLedgerHistoricImportDraftTransfer$Response>> => {
        return this.api.post('deleteLedgerHistoricImportDraftTransfer', request);
    }
    editHolderTransfer = async (request: EditHolderTransfer$Request): Promise<AxiosResponse<EditHolderTransfer$Response>> => {
        return this.api.post('editHolderTransfer', request);
    }
    getMyPendingTransferList = async (): Promise<AxiosResponse<GetMyPendingTransferList$Response>> => {
        return this.api.post('getMyPendingTransferList');
    }
    getTransferDetails = async (request: GetTransferDetails$Request): Promise<AxiosResponse<GetTransferDetails$Response>> => {
        return this.api.post('getTransferDetails', request);
    }
    getTransferList = async (): Promise<AxiosResponse<GetTransferList$Response>> => {
        return this.api.post('getTransferList');
    }
    saveCryptoTransferPayment = async (request: SaveCryptoTransferPayment$Request): Promise<AxiosResponse<SaveCryptoTransferPayment$Response>> => {
        return this.api.post('saveCryptoTransferPayment', request);
    }
    saveLedgerHistoricImportDraftTransfer = async (request: SaveLedgerHistoricImportDraftTransfer$Request): Promise<AxiosResponse<SaveLedgerHistoricImportDraftTransfer$Response>> => {
        return this.api.post('saveLedgerHistoricImportDraftTransfer', request);
    }

}

export class AuthApiService {
    constructor(private api: AxiosInstance) {}

    cancelSignAction = async (request: CancelSignAction$Request): Promise<AxiosResponse<CancelSignAction$Response>> => {
        return this.api.post('cancelSignAction', request);
    }
    login = async (request: Login$Request): Promise<AxiosResponse<Login$Response>> => {
        return this.api.post('login', request);
    }
    logout = async (): Promise<AxiosResponse<Logout$Response>> => {
        return this.api.post('logout');
    }
    pollRecoveryBySmsStatus = async (): Promise<AxiosResponse<PollRecoveryBySmsStatus$Response>> => {
        return this.api.post('pollRecoveryBySmsStatus');
    }
    pollSignActionStatus = async (request: PollSignActionStatus$Request): Promise<AxiosResponse<PollSignActionStatus$Response>> => {
        return this.api.post('pollSignActionStatus', request);
    }
    recoverBySms = async (request: RecoverBySms$Request): Promise<AxiosResponse<RecoverBySms$Response>> => {
        return this.api.post('recoverBySms', request);
    }
    resetPassword = async (request: ResetPassword$Request): Promise<AxiosResponse<ResetPassword$Response>> => {
        return this.api.post('resetPassword', request);
    }
    startRecoverBySms = async (): Promise<AxiosResponse<StartRecoverBySms$Response>> => {
        return this.api.post('startRecoverBySms');
    }
    startResetPassword = async (request: StartResetPassword$Request): Promise<AxiosResponse<StartResetPassword$Response>> => {
        return this.api.post('startResetPassword', request);
    }
    whoAmI = async (): Promise<AxiosResponse<WhoAmI$Response>> => {
        return this.api.post('whoAmI');
    }

}

export class UserApiService {
    constructor(private api: AxiosInstance) {}

    getAvailableProfiles = async (): Promise<AxiosResponse<GetAvailableProfiles$Response>> => {
        return this.api.post('getAvailableProfiles');
    }
    getUserDetails = async (): Promise<AxiosResponse<GetUserDetails$Response>> => {
        return this.api.post('getUserDetails');
    }
    saveUserOnboardingDetails = async (request: SaveUserOnboardingDetails$Request): Promise<AxiosResponse<SaveUserOnboardingDetails$Response>> => {
        return this.api.post('saveUserOnboardingDetails', request);
    }
    selectProfile = async (request: SelectProfile$Request): Promise<AxiosResponse<SelectProfile$Response>> => {
        return this.api.post('selectProfile', request);
    }
    sendAppDownloadInfo = async (): Promise<AxiosResponse<SendAppDownloadInfo$Response>> => {
        return this.api.post('sendAppDownloadInfo');
    }
    updateUserDetails = async (request: UpdateUserDetails$Request): Promise<AxiosResponse<UpdateUserDetails$Response>> => {
        return this.api.post('updateUserDetails', request);
    }
    validatePhone = async (request: ValidatePhone$Request): Promise<AxiosResponse<ValidatePhone$Response>> => {
        return this.api.post('validatePhone', request);
    }
    addCredential = async (request: AddCredential$Request): Promise<AxiosResponse<AddCredential$Response>> => {
        return this.api.post('addCredential', request);
    }
    changeCredential = async (request: ChangeCredential$Request): Promise<AxiosResponse<ChangeCredential$Response>> => {
        return this.api.post('changeCredential', request);
    }
    changePassword = async (request: ChangePassword$Request): Promise<AxiosResponse<ChangePassword$Response>> => {
        return this.api.post('changePassword', request);
    }
    createPassword = async (request: CreatePassword$Request): Promise<AxiosResponse<CreatePassword$Response>> => {
        return this.api.post('createPassword', request);
    }
    getUserCredentials = async (): Promise<AxiosResponse<GetUserCredentials$Response>> => {
        return this.api.post('getUserCredentials');
    }
    getUserCryptoWallet = async (): Promise<AxiosResponse<GetUserCryptoWallet$Response>> => {
        return this.api.post('getUserCryptoWallet');
    }
    reTriggerCredentialVerification = async (request: ReTriggerCredentialVerification$Request): Promise<AxiosResponse<ReTriggerCredentialVerification$Response>> => {
        return this.api.post('reTriggerCredentialVerification', request);
    }
    removeCredential = async (request: RemoveCredential$Request): Promise<AxiosResponse<RemoveCredential$Response>> => {
        return this.api.post('removeCredential', request);
    }
    replaceVerifiedApp = async (request: ReplaceVerifiedApp$Request): Promise<AxiosResponse<ReplaceVerifiedApp$Response>> => {
        return this.api.post('replaceVerifiedApp', request);
    }
    saveUserCryptoWallet = async (request: SaveUserCryptoWallet$Request): Promise<AxiosResponse<SaveUserCryptoWallet$Response>> => {
        return this.api.post('saveUserCryptoWallet', request);
    }
    verifyPhoneNumber = async (request: VerifyPhoneNumber$Request): Promise<AxiosResponse<VerifyPhoneNumber$Response>> => {
        return this.api.post('verifyPhoneNumber', request);
    }
    verifyWithSecondaryMethod = async (request: VerifyWithSecondaryMethod$Request): Promise<AxiosResponse<VerifyWithSecondaryMethod$Response>> => {
        return this.api.post('verifyWithSecondaryMethod', request);
    }

}

const api = new ApiService();
export default api;