import { Action, handleActions } from 'redux-actions'
import {
  GetStockHoldersSuccess,
  SetFilters,
  GetStockHoldersFail,
  GetStockHolders,
  GetNaturalPersonHolderDetailsSuccess,
  GetLegalEntityHolderDetailsSuccess,
  ResendHolderEmail,
  ResendHolderEmailFinished,
  GetNaturalPersonHolderDetails,
  GetLegalEntityHolderDetails,
  UpdateNaturalPersonHolderDetailsSuccess,
  UpdateLegalEntityHolderDetailsSuccess,
} from './HolderListViewActions'
import {
  AccountStockListDto,
  GetLegalEntityHolderDetails$Response,
  GetNaturalPersonHolderDetails$Response,
} from 'services/api.types'
import moment from 'moment'
import { ITime } from 'components/ui/form/time/Time'
import { IOption } from 'src/components/ui/form/selectbox/SelectBox'
import * as _ from 'lodash'
import { SelectUserProfile } from 'common/auth/AuthActions'
import { ILegalHolderFields, INaturalHolderFields } from 'components/functional/new-holder/NewHolderFieldNames'

export enum VotingPower {
  ALL = 'ALL',
  VOTING = 'VOTING',
  NON_VOTING = 'NON_VOTING',
}

export interface IFilters {
  searchTerm: string
  date: moment.Moment
  stockType: string
  votingPower: VotingPower
  time: ITime
}

export type partialFilters = Partial<IFilters>

class State {
  holderList: AccountStockListDto[] = []
  holderListLoading: boolean
  holderListLoaded: boolean
  naturalPersonHolderDetails: GetNaturalPersonHolderDetails$Response
  legalEntityHolderDetails: GetLegalEntityHolderDetails$Response
  naturalPersonHolderDetailsLoading: boolean
  legalEntityHolderDetailsLoading: boolean
  filters: IFilters = {
    searchTerm: '',
    stockType: '',
    date: null,
    votingPower: VotingPower.ALL,
    time: {
      time: '',
      meridiem: '',
    },
  }
  resendingHolderEmail: boolean = false
  stockTypeOptions: IOption[]
}

const defaultState = new State()

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      holderList: defaultState.holderList,
      holderListLoaded: defaultState.holderListLoaded,
      filters: defaultState.filters,
      stockTypeOptions: defaultState.stockTypeOptions,
    }),
    [String(GetStockHolders)]: (state: State): State => {
      return {
        ...state,
        holderListLoading: true,
        holderListLoaded: false,
      }
    },
    [String(GetStockHoldersSuccess)]: (state: State, action: Action<AccountStockListDto[]>): State => {
      const options: IOption[] = [{ label: 'ALL', value: '' }]
      action.payload.map(holder =>
        options.push({ label: holder.stockClass.stockName, value: holder.stockClass.stockName }),
      )

      return {
        ...state,
        holderList: action.payload,
        stockTypeOptions: _.uniqBy(options, 'value'),
        holderListLoading: false,
        holderListLoaded: true,
      }
    },
    [String(GetStockHoldersFail)]: (state: State): State => {
      return {
        ...state,
        holderListLoading: false,
        holderListLoaded: true,
      }
    },
    [String(SetFilters)]: (state: State, action: Action<partialFilters>): State => {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    },
    [String(UpdateNaturalPersonHolderDetailsSuccess)]: (state: State, action: Action<INaturalHolderFields>): State => ({
      ...state,
      naturalPersonHolderDetails: {
        ...state.naturalPersonHolderDetails,
        email: action.payload?.email,
      },
    }),
    [String(UpdateLegalEntityHolderDetailsSuccess)]: (state: State, action: Action<ILegalHolderFields>): State => ({
      ...state,
      legalEntityHolderDetails: {
        ...state.legalEntityHolderDetails,
        email: action.payload?.email,
      },
    }),
    [String(GetNaturalPersonHolderDetails)]: (state: State) => {
      return {
        ...state,
        naturalPersonHolderDetails: null,
        legalEntityHolderDetails: null,
        naturalPersonHolderDetailsLoading: true,
      }
    },
    [String(GetLegalEntityHolderDetails)]: (state: State) => {
      return {
        ...state,
        naturalPersonHolderDetails: null,
        legalEntityHolderDetails: null,
        legalEntityHolderDetailsLoading: true,
      }
    },
    [String(GetNaturalPersonHolderDetailsSuccess)]: (
      state: State,
      action: Action<GetNaturalPersonHolderDetails$Response>,
    ): State => {
      return {
        ...state,
        naturalPersonHolderDetailsLoading: false,
        naturalPersonHolderDetails: action.payload,
      }
    },
    [String(GetLegalEntityHolderDetailsSuccess)]: (
      state: State,
      action: Action<GetLegalEntityHolderDetails$Response>,
    ): State => {
      return {
        ...state,
        legalEntityHolderDetailsLoading: false,
        legalEntityHolderDetails: action.payload,
      }
    },
    [String(ResendHolderEmail)]: (state: State): State => {
      return {
        ...state,
        resendingHolderEmail: true,
      }
    },
    [String(ResendHolderEmailFinished)]: (state: State): State => {
      return {
        ...state,
        resendingHolderEmail: false,
      }
    },
  },

  new State(),
)

export { State as HolderListViewState }
