import { createAction } from 'redux-actions'

const ns = 'holderList/'

/* Get Stock Holders / Holder List */
export const GetStockHolders = createAction(`${ns}GET_STOCK_HOLDERS`)
export const GetStockHoldersSuccess = createAction(`${ns}GET_STOCK_HOLDERS_SUCCESS`)
export const GetStockHoldersFail = createAction(`${ns}GET_STOCK_HOLDERS_FAIL`)

export const SetFilters = createAction(`${ns}SET_FILTERS`)

export const EditHolderDetails = createAction(`${ns}EDIT_HOLDER_DETAILS`)
export const ViewHolderDetails = createAction(`${ns}VIEW_HOLDER_DETAILS`)

export const ResendHolderEmail = createAction(`${ns}RESEND_HOLDER_EMAIL`)
export const ResendHolderEmailFinished = createAction(`${ns}RESEND_HOLDER_EMAIL_FINISHED`)

export const GetNaturalPersonHolderDetails = createAction(`${ns}GET_NATURAL_PERSON_HOLDER_DETAILS`)
export const GetNaturalPersonHolderDetailsSuccess = createAction(`${ns}GET_NATURAL_PERSON_HOLDER_DETAILS_SUCCESS`)

export const GetLegalEntityHolderDetails = createAction(`${ns}GET_LEGAL_ENTITY_HOLDER_DETAILS`)
export const GetLegalEntityHolderDetailsSuccess = createAction(`${ns}GET_LEGAL_ENTITY_HOLDER_DETAILS_SUCCESS`)

export const UpdateNaturalPersonHolderDetails = createAction(`${ns}UPDATE_NATURAL_PERSON_HOLDER_DETAILS`)
export const UpdateNaturalPersonHolderDetailsSuccess = createAction(`${ns}UPDATE_NATURAL_PERSON_HOLDER_DETAILS_SUCCESS`)
export const UpdateNaturalPersonHolderDetailsFailure = createAction(`${ns}UPDATE_NATURAL_PERSON_HOLDER_DETAILS_FAILURE`)

export const UpdateLegalEntityHolderDetails = createAction(`${ns}UPDATE_LEGAL_ENTITY_HOLDER_DETAILS`)
export const UpdateLegalEntityHolderDetailsSuccess = createAction(`${ns}UPDATE_LEGAL_ENTITY_HOLDER_DETAILS_SUCCESS`)
export const UpdateLegalEntityHolderDetailsFailure = createAction(`${ns}UPDATE_LEGAL_ENTITY_HOLDER_DETAILS_FAILURE`)

export const CreateNaturalPerson = createAction(`${ns}CREATE_NATURAL_PERSON`)
export const CreateLegalEntity = createAction(`${ns}CREATE_LEGAL_ENTITY`)

export const GoBackToHoldersList = createAction(`${ns}GO_BACK_TO_HOLDERS_LIST`)
