export interface AcceptNotification$Request {
    id: LedgerNotificationId;
}

export interface AcceptNotification$Response {
}

export interface AcceptTransfer$Request {
    id: TransferId;
}

export interface AcceptTransfer$Response {
    signActionId: SignActionId;
}

export enum AccessRecoveryBySmsStatusType {
    CREATED = 'CREATED',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    TIMEOUT = 'TIMEOUT',
}

export interface AccountDto {
    calculatedName: string;
    draftAccount: boolean;
    holders: HolderDto[];
    id: AccountId;
    ledgerRootAccount: boolean;
    temporaryAccount: boolean;
}

export type AccountId = string;

export interface AccountStockListDto {
    accountId: AccountId;
    accountName: string;
    holderCredentials: HolderUserWithVerifiedCredentialsDto;
    holders: HolderDto[];
    numberOfShares: number;
    pendingNumberOfShares: number;
    stockClass: StockClassDto;
    usableNumberOfShares: number;
}

export interface AddCredential$Request {
    type: CredentialType;
    value: string;
}

export interface AddCredential$Response {
    id: CredentialId;
    signActionId: SignActionId;
}

export interface AddLedgerAdmin$Request {
    email: string;
}

export interface AddLedgerAdmin$Response {
}

export interface AddLegalEntityCompanyDetails$Request {
    address: AddressRequest;
    apartment: string;
    name: string;
    streetAddress: string;
    tin: string;
}

export interface AddLegalEntityCompanyDetails$Response {
}

export interface AddMyselfAsHolder$Request {
}

export interface AddMyselfAsHolder$Response {
    accountId: AccountId;
    holderId: HolderId;
}

export interface AddressDto {
    apartment: string;
    buildingNumber: string;
    city: string;
    country: string;
    id: AddressId;
    state: string;
    streetAddress: string;
    zipCode: string;
}

export type AddressId = number;

export interface AddressRequest {
    apartment: string;
    buildingNumber: string;
    city: string;
    country: string;
    notEmpty: boolean;
    state: string;
    streetAddress: string;
    zipCode: string;
}

export interface CancelSignAction$Request {
    id: SignActionId;
}

export interface CancelSignAction$Response {
}

export interface CancelTransfer$Request {
    documents: CancelTransfer$Request$UploadedDocumentRequest[];
    reason: string;
    transferId: TransferId;
}

export interface CancelTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface CancelTransfer$Response {
    signActionId: SignActionId;
}

export interface ChangeCredential$Request {
    id: CredentialId;
    type: CredentialType;
    value: string;
}

export interface ChangeCredential$Response {
    id: CredentialId;
    signActionId: SignActionId;
}

export interface ChangePassword$Request {
    newPassword: SensitiveString;
    oldPassword: SensitiveString;
}

export interface ChangePassword$Response {
}

export interface ConfirmBulkStockBalanceImport$Request {
}

export interface ConfirmBulkStockBalanceImport$Response {
    signActionId: SignActionId;
}

export interface ConfirmLedgerHistoricImportDraft$Request {
}

export interface ConfirmLedgerHistoricImportDraft$Response {
    signActionId: SignActionId;
}

export interface ConfirmStockClass$Request {
    stockClasses: ConfirmStockClass$Request$ConfirmStockClassRequest[];
}

export interface ConfirmStockClass$Request$ConfirmStockClassRequest {
    id: StockClassId;
}

export interface ConfirmStockClass$Response {
}

export type ContractId = string;

export interface ContractListDto {
    createdAt: number;
    document: UploadedDocumentDto;
    id: ContractId;
}

export interface CreateBulkHolder$Request {
    holders: CreateBulkHolder$Request$Holder[];
}

export interface CreateBulkHolder$Request$Holder {
    externalId: string;
    holderDraft: boolean;
    legalEntity: CreateBulkHolder$Request$LegalEntity;
    naturalPerson: CreateBulkHolder$Request$NaturalPerson;
}

export interface CreateBulkHolder$Request$LegalEntity {
    address: AddressRequest;
    email: string;
    name: string;
    tin: string;
}

export interface CreateBulkHolder$Request$NaturalPerson {
    address: AddressRequest;
    email: string;
    givenNames: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    ssn: string;
}

export interface CreateBulkHolder$Response {
    holders: CreateBulkHolder$Response$Holder[];
}

export interface CreateBulkHolder$Response$Holder {
    accountId: AccountId;
    externalId: string;
    holderId: HolderId;
}

export interface CreateBulkIssuance$Request {
    accountStocks: CreateBulkIssuance$Request$AccountStock[];
}

export interface CreateBulkIssuance$Request$AccountStock {
    accountId: AccountId;
    documents: CreateBulkIssuance$Request$UploadedDocumentRequest[];
    numberOfShares: number;
    payment: CreateBulkIssuance$Request$Payment;
    stockClassId: StockClassId;
    stockRestriction: string;
}

export enum CreateBulkIssuance$Request$CryptoCurrency {
    ETH = 'ETH',
}

export enum CreateBulkIssuance$Request$CurrencyType {
    CRYPTO = 'CRYPTO',
    FIAT_MONEY = 'FIAT_MONEY',
}

export interface CreateBulkIssuance$Request$Payment {
    amountPaid: number;
    cryptoCurrency: CreateBulkIssuance$Request$CryptoCurrency;
    currencyType: CreateBulkIssuance$Request$CurrencyType;
}

export interface CreateBulkIssuance$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateBulkIssuance$Response {
    signActionId: SignActionId;
}

export interface CreateBulkStockBalance$Request {
    documents: CreateBulkStockBalance$Request$UploadedDocumentRequest[];
    holderBalances: CreateBulkStockBalance$Request$HolderBalance[];
}

export interface CreateBulkStockBalance$Request$HolderBalance {
    accountId: AccountId;
    numberOfShares: number;
    stockClassId: StockClassId;
}

export interface CreateBulkStockBalance$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateBulkStockBalance$Response {
}

export interface CreateBulkTransfer$Request {
    generalDocuments: CreateBulkTransfer$Request$UploadedDocumentRequest[];
    nonHistoricTransfers: boolean;
    transfers: CreateBulkTransfer$Request$CreateTransfer[];
}

export interface CreateBulkTransfer$Request$CreateTransfer {
    certifiedTransfer: string;
    comment: string;
    dateOfTransfer: string;
    documents: CreateBulkTransfer$Request$UploadedDocumentRequest[];
    from: AccountId;
    informativeRow: boolean;
    numberOfShares: number;
    order: number;
    payment: CreateBulkTransfer$Request$CreateTransfer$Payment;
    stockClassId: StockClassId;
    to: AccountId;
    type: CreateBulkTransfer$Request$CreateTransfer$TransferAction;
}

export enum CreateBulkTransfer$Request$CreateTransfer$CryptoCurrency {
    ETH = 'ETH',
}

export enum CreateBulkTransfer$Request$CreateTransfer$CurrencyType {
    CRYPTO = 'CRYPTO',
    FIAT_MONEY = 'FIAT_MONEY',
}

export interface CreateBulkTransfer$Request$CreateTransfer$Payment {
    amountPaid: number;
    cryptoCurrency: CreateBulkTransfer$Request$CreateTransfer$CryptoCurrency;
    currencyType: CreateBulkTransfer$Request$CreateTransfer$CurrencyType;
}

export enum CreateBulkTransfer$Request$CreateTransfer$TransferAction {
    BUYBACK = 'BUYBACK',
    ISSUANCE = 'ISSUANCE',
    RETIREMENT = 'RETIREMENT',
    TRANSFER = 'TRANSFER',
}

export interface CreateBulkTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateBulkTransfer$Response {
    signActionId: SignActionId;
}

export interface CreateHolderTransfer$Request {
    amountPaid: number;
    cryptoCurrency: CreateHolderTransfer$Request$CryptoCurrency;
    currencyType: CurrencyType;
    dateOfTransfer: string;
    dealRestrictions: string;
    documents: CreateHolderTransfer$Request$UploadedDocumentRequest[];
    numberOfShares: number;
    stockClassId: StockClassId;
    to: CreateHolderTransfer$Request$ToRequest;
}

export enum CreateHolderTransfer$Request$CryptoCurrency {
    ETH = 'ETH',
}

export interface CreateHolderTransfer$Request$ToRequest {
    email: string;
    holderType: HolderType;
    name: string;
    phoneNumber: string;
}

export interface CreateHolderTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateHolderTransfer$Response {
    signActionId: SignActionId;
}

export interface CreateIssuance$Request {
    accountStocks: CreateIssuance$Request$AccountStock[];
    stockClassId: StockClassId;
}

export interface CreateIssuance$Request$AccountStock {
    accountId: AccountId;
    documents: CreateIssuance$Request$UploadedDocumentRequest[];
    numberOfShares: number;
    payment: CreateIssuance$Request$Payment;
    stockRestriction: string;
}

export interface CreateIssuance$Request$AmountPaid {
    money: number;
    nonCash: CreateIssuance$Request$NonCash;
}

export interface CreateIssuance$Request$FullyPaid {
    money: number;
    nonCash: CreateIssuance$Request$NonCash;
}

export interface CreateIssuance$Request$NonCash {
    comment: string;
    type: NonCashType;
    value: string;
}

export interface CreateIssuance$Request$PartlyPaid {
    amountPaid: CreateIssuance$Request$AmountPaid;
    totalAmountToBePaid: CreateIssuance$Request$TotalAmountToBePaid;
}

export interface CreateIssuance$Request$Payment {
    fullyPaid: CreateIssuance$Request$FullyPaid;
    partlyPaid: CreateIssuance$Request$PartlyPaid;
}

export interface CreateIssuance$Request$TotalAmountToBePaid {
    money: number;
    nonCash: CreateIssuance$Request$NonCash;
    reminderDate: string;
}

export interface CreateIssuance$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateIssuance$Response {
    id: IssuanceId;
    signActionId: SignActionId;
}

export interface CreateLegalHolder$Request {
    address: AddressRequest;
    apartment: string;
    email: string;
    holderDraft: boolean;
    name: string;
    streetAddress: string;
    tin: string;
}

export interface CreateLegalHolder$Response {
    accountId: AccountId;
    existingHolder: boolean;
    holderId: HolderId;
    mismatchProblem: CreateLegalHolder$Response$MismatchProblem;
}

export interface CreateLegalHolder$Response$MismatchProblem {
    holders: HolderDto[];
}

export interface CreateNaturalHolder$Request {
    address: AddressRequest;
    apartment: string;
    email: string;
    givenNames: string;
    holderDraft: boolean;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    ssn: string;
    streetAddress: string;
}

export interface CreateNaturalHolder$Response {
    accountId: AccountId;
    existingHolder: boolean;
    holderId: HolderId;
    mismatchProblem: CreateNaturalHolder$Response$MismatchProblem;
}

export interface CreateNaturalHolder$Response$MismatchProblem {
    holders: HolderDto[];
}

export interface CreatePassword$Request {
    newPassword: SensitiveString;
}

export interface CreatePassword$Response {
}

export interface CreateRetirementTransfer$Request {
    amountPaid: number;
    comment: string;
    documents: CreateRetirementTransfer$Request$UploadedDocumentRequest[];
    numberOfShares: number;
    stockClassId: StockClassId;
}

export interface CreateRetirementTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateRetirementTransfer$Response {
    signActionId: SignActionId;
}

export interface CreateStockClass$Request {
    documents: CreateStockClass$Request$UploadedDocumentRequest[];
    name: string;
    parValue: number;
    reason: string;
    restrictions: string;
    totalNumberOfShares: number;
    type: StockType;
    votesPerShare: number;
    voting: boolean;
}

export interface CreateStockClass$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateStockClass$Response {
    id: StockClassId;
    signActionId: SignActionId;
}

export interface CreateStockSeries$Request {
    documents: CreateStockSeries$Request$UploadedDocumentRequest[];
    name: string;
    parValue: number;
    parentId: StockClassId;
    reason: string;
    restrictions: string;
    totalNumberOfShares: number;
    votesPerShare: number;
    voting: boolean;
}

export interface CreateStockSeries$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateStockSeries$Response {
    id: StockClassId;
    signActionId: SignActionId;
}

export interface CreateTransfer$Request {
    amountPaid: number;
    dateOfTransfer: string;
    dealRestrictions: string;
    documents: CreateTransfer$Request$UploadedDocumentRequest[];
    from: AccountId;
    numberOfShares: number;
    stockClassId: StockClassId;
    to: AccountId;
}

export interface CreateTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface CreateTransfer$Response {
    signActionId: SignActionId;
}

export interface CredentialDto {
    id: CredentialId;
    type: CredentialType;
    userId: UserId;
    value: string;
    verified: boolean;
}

export type CredentialId = string;

export enum CredentialType {
    APP = 'APP',
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
    PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum CurrencyType {
    CRYPTO = 'CRYPTO',
    FIAT_MONEY = 'FIAT_MONEY',
}

export interface DeactivateProfile$Request {
    id: ProfileId;
}

export interface DeactivateProfile$Response {
}

export interface DeclineNotification$Request {
    id: LedgerNotificationId;
}

export interface DeclineNotification$Response {
}

export interface DeclineTransfer$Request {
    id: TransferId;
}

export interface DeclineTransfer$Response {
    signActionId: SignActionId;
}

export interface DeleteDraft$Request {
    key: string;
    ledgerAdminDraft: boolean;
    type: string;
}

export interface DeleteDraft$Response {
}

export interface DeleteLedgerHistoricImportDraftTransfer$Request {
    id: TransferHistoricImportDraftId;
}

export interface DeleteLedgerHistoricImportDraftTransfer$Response {
}

export interface DeleteStockClass$Request {
    stockClassId: StockClassId;
}

export interface DeleteStockClass$Response {
    signActionId: SignActionId;
}

export enum DocumentType {
    CONTRACT = 'CONTRACT',
    CREATE_LEDGER = 'CREATE_LEDGER',
    CREATE_STOCK_CLASS = 'CREATE_STOCK_CLASS',
    DELETE_STOCK_CLASS = 'DELETE_STOCK_CLASS',
    HISTORIC_IMPORT = 'HISTORIC_IMPORT',
    STOCKHOLDER_DOCUMENT = 'STOCKHOLDER_DOCUMENT',
    STOCK_BALANCE = 'STOCK_BALANCE',
    TRANSFER = 'TRANSFER',
    UPDATE_LEDGER = 'UPDATE_LEDGER',
    UPDATE_STOCK_CLASS = 'UPDATE_STOCK_CLASS',
}

export interface DraftDto {
    data: string;
    id: DraftId;
    key: string;
    type: string;
}

export type DraftId = number;

export interface EditHolderTransfer$Request {
    id: TransferId;
    to: EditHolderTransfer$Request$ToRequest;
}

export interface EditHolderTransfer$Request$ToRequest {
    email: string;
    holderType: HolderType;
    name: string;
    phoneNumber: string;
}

export interface EditHolderTransfer$Response {
    signActionId: SignActionId;
}

export interface GenerateContractFile$Request {
    type: GenerateContractFile$Request$Type;
}

export enum GenerateContractFile$Request$Type {
    HOLDER_ONBOARDING = 'HOLDER_ONBOARDING',
    LEDGER_ADDITIONAL_ADMIN = 'LEDGER_ADDITIONAL_ADMIN',
    LEDGER_CREATION = 'LEDGER_CREATION',
}

export interface GenerateContractFile$Response {
    contractId: ContractId;
}

export interface GetAccountList$Request {
}

export interface GetAccountList$Response {
    list: AccountStockListDto[];
}

export interface GetAccountStockList$Request {
    id: AccountId;
}

export interface GetAccountStockList$Response {
    account: MyAccountStockListDto;
}

export interface GetAvailableProfiles$Request {
}

export interface GetAvailableProfiles$Response {
    ledgers: LedgerWithProfilesDto[];
}

export interface GetContractList$Request {
}

export interface GetContractList$Response {
    contracts: ContractListDto[];
}

export interface GetDashboard$Request {
}

export interface GetDashboard$Response {
    ledger: GetDashboard$Response$Ledger;
    myStocks: MyAccountStockListDto[];
    progress: GetDashboard$Response$Progress;
    stocks: StockListDto[];
    transfers: TransferListDto[];
}

export interface GetDashboard$Response$HistoricImport {
    completed: boolean;
}

export interface GetDashboard$Response$Ledger {
    holderToHolderTransfersEnabled: boolean;
    ledgerAdminUsers: LedgerAdminUserDto[];
    subscriptionExpired: boolean;
}

export interface GetDashboard$Response$Progress {
    additionalAdminOnboarding: boolean;
    companyInfoAdded: boolean;
    hasCredentials: boolean;
    hasDisabledProfiles: boolean;
    hasVerifiedAppId: boolean;
    hasVerifiedPhoneNumber: boolean;
    historicImport: GetDashboard$Response$HistoricImport;
    holderListExportGenerated: boolean;
    issuanceAdded: boolean;
    ledgerExists: boolean;
    legalEntityInfoConfirmed: boolean;
    legalHolder: boolean;
    onboardingContractSigned: boolean;
    stockInfoAdded: boolean;
    userInfoAdded: boolean;
}

export interface GetDraft$Request {
    key: string;
    ledgerAdminDraft: boolean;
    type: string;
}

export interface GetDraft$Response {
    drafts: DraftDto[];
}

export interface GetHolderDocuments$HolderDocument {
    createdAt: number;
    createdBy: string;
    documentType: DocumentType;
    fileName: string;
    notes: string;
    token: string;
}

export interface GetHolderDocuments$Request {
    holderId: HolderId;
}

export interface GetHolderDocuments$Response {
    documents: GetHolderDocuments$HolderDocument[];
}

export interface GetLedgerBasicDetails$Request {
}

export interface GetLedgerBasicDetails$Response {
    company: GetLedgerBasicDetails$Response$Company;
    editableLedger: boolean;
    historicLedger: boolean;
    ledgerAdminUsers: GetLedgerBasicDetails$Response$LedgerAdminUserDto[];
    ledgerState: LedgerState;
    treasury: GetLedgerBasicDetails$Response$Treasury;
    treasuryStockAccount: AccountId;
}

export interface GetLedgerBasicDetails$Response$Company {
    address: AddressDto;
    name: string;
    registrationNumber: string;
}

export interface GetLedgerBasicDetails$Response$LedgerAdminUserDto {
    emails: string[];
    fullName: string;
    phones: string[];
}

export interface GetLedgerBasicDetails$Response$Treasury {
    accountId: AccountId;
    hasShares: boolean;
    name: string;
}

export interface GetLedgerCryptoWallet$Request {
}

export interface GetLedgerCryptoWallet$Response {
    walletAddress: string;
}

export interface GetLedgerDetails$Request {
}

export interface GetLedgerDetails$Response {
    documents: UploadedDocumentDto[];
    id: LedgerId;
    name: string;
    officeAddress: AddressDto;
    registrationDate: string;
    registrationNumber: string;
    type: LedgerCompanyType;
}

export interface GetLedgerDocuments$DocumentStockClass {
    series: boolean;
    stockName: string;
    type: StockType;
}

export interface GetLedgerDocuments$LedgerDocument {
    createdAt: number;
    createdBy: string;
    documentType: DocumentType;
    fileName: string;
    stockClass: GetLedgerDocuments$DocumentStockClass;
    token: string;
}

export interface GetLedgerDocuments$Request {
}

export interface GetLedgerDocuments$Response {
    list: GetLedgerDocuments$LedgerDocument[];
}

export interface GetLedgerHistoricImportDraft$Request {
}

export interface GetLedgerHistoricImportDraft$Response {
    stockClasses: GetLedgerHistoricImportDraft$Response$StockClass[];
    stockholders: GetLedgerHistoricImportDraft$Response$StockHolder[];
}

export interface GetLedgerHistoricImportDraft$Response$AccountStock {
    accountId: AccountId;
    amountPaid: number;
    documents: UploadedDocumentDto[];
    fromAccountId: AccountId;
    id: TransferHistoricImportDraftId;
    informativeRow: boolean;
    notes: string;
    numberOfShares: number;
    restrictions: string;
    transferDate: number;
}

export interface GetLedgerHistoricImportDraft$Response$StockClass {
    accountStocks: GetLedgerHistoricImportDraft$Response$AccountStock[];
    stockClassId: StockClassId;
}

export interface GetLedgerHistoricImportDraft$Response$StockHolder {
    accountId: AccountId;
    email: string;
    holderId: HolderId;
    lastName: string;
    middleName: string;
    name: string;
    nameSuffix: string;
    type: HolderType;
}

export interface GetLedgerStockBalance$Request {
    stateAt: number;
    voting: boolean;
}

export interface GetLedgerStockBalance$Response {
    balance: LedgerBalanceDto;
}

export interface GetLedgerUserList$Request {
}

export interface GetLedgerUserList$Response {
    list: LedgerAdminUserDto[];
}

export interface GetLegalEntityCompanyDetails$Request {
}

export interface GetLegalEntityCompanyDetails$Response {
    holderId: HolderId;
    name: string;
    officeAddress: AddressDto;
    tin: string;
}

export interface GetLegalEntityHolderDetails$Request {
    holderId: HolderId;
}

export interface GetLegalEntityHolderDetails$Response {
    confirmed: boolean;
    email: string;
    emails: HolderCredential[];
    name: string;
    officeAddress: AddressDto;
    onboarded: boolean;
    phones: HolderCredential[];
    tin: string;
}

export interface GetMyAccounts$Account {
    id: AccountId;
    name: string;
}

export interface GetMyAccounts$Request {
}

export interface GetMyAccounts$Response {
    accounts: GetMyAccounts$Account[];
}

export interface GetMyPendingTransferList$Request {
}

export interface GetMyPendingTransferList$Response {
    list: MyPendingTransferListDto[];
}

export interface GetMyStockList$Request {
}

export interface GetMyStockList$Response {
    accounts: MyAccountStockListDto[];
}

export interface GetNaturalPersonHolderDetails$Request {
    holderId: HolderId;
}

export interface GetNaturalPersonHolderDetails$Response {
    address: AddressDto;
    confirmed: boolean;
    email: string;
    emails: HolderCredential[];
    givenNames: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    onboarded: boolean;
    phones: HolderCredential[];
    ssn: string;
}

export interface GetNotifications$Request {
}

export interface GetNotifications$Response {
    notifications: GetNotifications$Response$Notification[];
}

export interface GetNotifications$Response$Content {
    values: { [key: string ]: string;};
}

export interface GetNotifications$Response$Notification {
    content: GetNotifications$Response$Content;
    createdAt: number;
    id: LedgerNotificationId;
    type: GetNotifications$Response$Type;
}

export enum GetNotifications$Response$Type {
    HOLDER_UPDATE = 'HOLDER_UPDATE',
}

export interface GetPendingLedgerAdminEmailList$Request {
}

export interface GetPendingLedgerAdminEmailList$Response {
    list: PendingLedgerAdminEmailDto[];
}

export interface GetStockList$Request {
}

export interface GetStockList$Response {
    list: StockListDto[];
}

export interface GetTransferDetails$Request {
    id: TransferId;
    pendingTransfer: boolean;
}

export interface GetTransferDetails$Response {
    adminTransfer: boolean;
    amountPaid: number;
    canAccept: boolean;
    canCancel: boolean;
    canDecline: boolean;
    canEdit: boolean;
    cancellation: GetTransferDetails$Response$Cancellation;
    certificatedTransfer: string;
    createdAt: number;
    createdBy: string;
    createdByAdmin: GetTransferDetails$Response$CreatedByAdmin;
    declinedAt: number;
    declinedBy: string;
    declinedByAdmin: boolean;
    documents: UploadedDocumentDto[];
    from: GetTransferDetails$Response$Participant;
    fromAccountName: string;
    historicImport: boolean;
    informativeRow: boolean;
    issuance: boolean;
    numberOfShares: number;
    payment: GetTransferDetails$Response$Payment;
    pendingTransfer: boolean;
    pendingTransferAttributes: GetTransferDetails$Response$PendingTransferAttributes;
    restrictions: string;
    status: TransferStatusType;
    stockClass: GetTransferDetails$Response$StockClass;
    stockClassName: string;
    to: GetTransferDetails$Response$Participant;
    toAccountName: string;
}

export interface GetTransferDetails$Response$Cancellation {
    cancellationDocuments: UploadedDocumentDto[];
    cancelledAt: number;
    cancelledBy: string;
    cancelledByEmails: string[];
    reason: string;
}

export interface GetTransferDetails$Response$CreatedByAdmin {
    emails: string[];
    name: string;
}

export interface GetTransferDetails$Response$Participant {
    emails: string[];
    name: string;
    onboarded: boolean;
    type: HolderType;
}

export interface GetTransferDetails$Response$Payment {
    amountPaid: number;
    currency: string;
    currencyType: CurrencyType;
    externalId: string;
    toAddress: string;
}

export interface GetTransferDetails$Response$PendingTransferAttributes {
    phoneNumber: string;
}

export interface GetTransferDetails$Response$StockClass {
    hasRestrictions: boolean;
    restrictions: string;
    stockClassName: string;
}

export interface GetTransferList$Request {
}

export interface GetTransferList$Response {
    transfers: TransferListDto[];
}

export interface GetTransferRestrictions$Request {
}

export interface GetTransferRestrictions$Response {
    holderToHolderTransfersDisabled: boolean;
}

export interface GetUserCredentials$Request {
}

export interface GetUserCredentials$Response {
    emails: CredentialDto[];
    keys: CredentialDto[];
    phones: CredentialDto[];
}

export interface GetUserCryptoWallet$Request {
}

export interface GetUserCryptoWallet$Response {
    walletAddress: string;
}

export interface GetUserDetails$Request {
}

export interface GetUserDetails$Response {
    address: AddressDto;
    apartment: string;
    dateOfBirth: string;
    documents: UploadedDocumentDto[];
    givenNames: string;
    id: UserId;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    previousNames: string[];
    ssn: string;
    streetAddress: string;
}

export interface HolderCredential {
    value: string;
}

export interface HolderDto {
    address: AddressDto;
    confirmed: boolean;
    email: string;
    emailSentAt: number;
    id: HolderId;
    lastName: string;
    ledgerId: LedgerId;
    middleName: string;
    name: string;
    nameSuffix: string;
    phones: string[];
    registrationNumber: string;
    ssn: string;
    type: HolderType;
    userId: UserId;
}

export type HolderId = string;

export enum HolderType {
    LEGAL_ENTITY = 'LEGAL_ENTITY',
    NATURAL_PERSON = 'NATURAL_PERSON',
}

export interface HolderUserWithVerifiedCredentialsDto {
    emails: string[];
    holderId: HolderId;
    phoneNumbers: string[];
}

export type IssuanceId = string;

export interface LedgerAdminUserDto {
    active: boolean;
    emails: string[];
    givenNames: string;
    id: UserId;
    middleName: string;
    name: string;
    nameSuffix: string;
    phones: string[];
    profileId: ProfileId;
}

export interface LedgerBalanceDto {
    balances: LedgerBalanceDto$AccountBalanceDto[];
    stateAt: number;
    stockClasses: StockClassDto[];
}

export interface LedgerBalanceDto$AccountBalanceDto {
    account: AccountDto;
    accountStockBalances: LedgerBalanceDto$AccountBalanceDto$AccountStockBalanceDto[];
}

export interface LedgerBalanceDto$AccountBalanceDto$AccountStockBalanceDto {
    shares: number;
    stockClassId: StockClassId;
}

export enum LedgerCompanyType {
    CORPORATION = 'CORPORATION',
    LLC = 'LLC',
}

export type LedgerId = string;

export type LedgerNotificationId = string;

export enum LedgerState {
    DELAWARE = 'DELAWARE',
    WYOMING = 'WYOMING',
}

export interface LedgerWithProfilesDto {
    fileNumber: string;
    name: string;
    profiles: ProfileDto[];
}

export enum Login$LoginStatus {
    APP_SECOND_FACTOR_AUTH_NEEDED = 'APP_SECOND_FACTOR_AUTH_NEEDED',
    SUCCESS = 'SUCCESS',
}

export interface Login$Request {
    email: string;
    password: SensitiveString;
}

export interface Login$Response {
    code: Login$LoginStatus;
    signActionId: SignActionId;
}

export interface Logout$Request {
}

export interface Logout$Response {
}

export interface MyAccountStockListDto {
    id: AccountId;
    name: string;
    stocks: MyAccountStockListDto$StockItem[];
}

export interface MyAccountStockListDto$StockItem {
    classId: StockClassId;
    parValue: number;
    pendingNumberOfShares: number;
    restrictions: string;
    stockName: string;
    totalNumberOfShares: number;
    type: StockType;
    usableNumberOfShares: number;
    voting: boolean;
    votingRights: number;
}

export interface MyPendingTransferListDto {
    adminTransfer: boolean;
    amountPaid: number;
    createdAt: number;
    cryptoTransfer: boolean;
    currency: string;
    currencyType: CurrencyType;
    fromAccount: MyPendingTransferListDto$AccountDto;
    id: TransferId;
    issuance: boolean;
    numberOfShares: number;
    restrictions: string;
    stockClassName: string;
    toAccount: MyPendingTransferListDto$AccountDto;
}

export interface MyPendingTransferListDto$AccountDto {
    calculatedName: string;
    id: AccountId;
}

export enum NonCashType {
    BENEFIT = 'BENEFIT',
    INTANGIBLE = 'INTANGIBLE',
    TANGIBLE = 'TANGIBLE',
}

export interface PendingLedgerAdminEmailDto {
    clicked: boolean;
    createdAt: number;
    email: string;
    id: PendingLedgerAdminEmailId;
}

export type PendingLedgerAdminEmailId = string;

export interface PollRecoveryBySmsStatus$Request {
}

export interface PollRecoveryBySmsStatus$Response {
    status: AccessRecoveryBySmsStatusType;
}

export interface PollSignActionStatus$Request {
    id: SignActionId;
}

export interface PollSignActionStatus$Response {
    error: PollSignActionStatus$Response$Error;
    hash: string;
    status: PollSignActionStatus$Status;
    type: PollSignActionStatus$SigningType;
    verificationCode: SignActionVerificationCode;
}

export interface PollSignActionStatus$Response$Error {
    code: string;
    message: string;
}

export enum PollSignActionStatus$SigningType {
    AUTHENTICATE = 'AUTHENTICATE',
    SIGN = 'SIGN',
    SIGN_LINKING = 'SIGN_LINKING',
}

export enum PollSignActionStatus$Status {
    CANCELLED = 'CANCELLED',
    CREATED = 'CREATED',
    DENIED = 'DENIED',
    EXPIRED = 'EXPIRED',
    SIGNED = 'SIGNED',
    WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
}

export interface ProfileDto {
    id: ProfileId;
    ledgerId: LedgerId;
    name: string;
    role: ProfileRole;
}

export type ProfileId = string;

export enum ProfileRole {
    DELEGATED_HOLDER = 'DELEGATED_HOLDER',
    HOLDER = 'HOLDER',
    LEDGER_ADMIN = 'LEDGER_ADMIN',
}

export interface ReTriggerCredentialVerification$Request {
    id: CredentialId;
}

export interface ReTriggerCredentialVerification$Response {
    signActionId: SignActionId;
}

export interface ReactivateProfile$Request {
    id: ProfileId;
}

export interface ReactivateProfile$Response {
}

export interface RecoverBySms$Request {
    appKey: string;
    smsCode: string;
}

export interface RecoverBySms$Response {
    signActionId: SignActionId;
    status: RecoverBySms$Status;
}

export enum RecoverBySms$Status {
    FAILURE = 'FAILURE',
    SUCCESS = 'SUCCESS',
}

export interface RemoveCredential$Request {
    id: CredentialId;
}

export interface RemoveCredential$Response {
}

export interface ReplaceVerifiedApp$Request {
    value: string;
}

export interface ReplaceVerifiedApp$Response {
    id: CredentialId;
    signActionId: SignActionId;
}

export interface ResendHolderEmail$Request {
    email: string;
    holderId: HolderId;
}

export interface ResendHolderEmail$Response {
}

export interface ResetPassword$Request {
    newPassword: string;
}

export interface ResetPassword$Response {
    signActionId: SignActionId;
}

export interface RevokePendingLedgerAdminEmail$Request {
    id: PendingLedgerAdminEmailId;
}

export interface RevokePendingLedgerAdminEmail$Response {
}

export interface SaveCryptoTransferPayment$Request {
    id: TransferId;
    transactionHash: string;
}

export interface SaveCryptoTransferPayment$Response {
    status: SaveCryptoTransferPayment$Response$Status;
}

export enum SaveCryptoTransferPayment$Response$Status {
    ALREADY_COMPLETED = 'ALREADY_COMPLETED',
    EXPIRED = 'EXPIRED',
    HASH_ALREADY_SENT = 'HASH_ALREADY_SENT',
    OK = 'OK',
}

export interface SaveDraft$Request {
    data: string;
    key: string;
    ledgerAdminDraft: boolean;
    type: string;
}

export interface SaveDraft$Response {
}

export interface SaveHolderDocuments$Request {
    comment: string;
    documents: SaveHolderDocuments$Request$UploadedDocumentRequest[];
    holderId: HolderId;
}

export interface SaveHolderDocuments$Request$UploadedDocumentRequest {
    token: string;
}

export interface SaveHolderDocuments$Response {
}

export interface SaveLedgerCryptoWallet$Request {
    walletAddress: string;
}

export interface SaveLedgerCryptoWallet$Response {
}

export interface SaveLedgerDetails$Request {
    documents: SaveLedgerDetails$Request$UploadedDocumentRequest[];
    historicLedger: boolean;
    name: string;
    officeAddress: AddressRequest;
    registrationDate: string;
    registrationNumber: string;
}

export interface SaveLedgerDetails$Request$UploadedDocumentRequest {
    token: string;
}

export interface SaveLedgerDetails$Response {
    ledgerId: LedgerId;
}

export interface SaveLedgerHistoricImportDraftTransfer$Request {
    amountPaid: number;
    documents: SaveLedgerHistoricImportDraftTransfer$Request$UploadedDocumentRequest[];
    id: TransferHistoricImportDraftId;
    informativeRow: SaveLedgerHistoricImportDraftTransfer$Request$InformativeRow;
    notes: string;
    numberOfShares: number;
    restrictions: string;
    stockClassId: StockClassId;
    toAccountId: AccountId;
}

export interface SaveLedgerHistoricImportDraftTransfer$Request$InformativeRow {
    fromAccountId: AccountId;
    transferDate: string;
}

export interface SaveLedgerHistoricImportDraftTransfer$Request$UploadedDocumentRequest {
    token: string;
}

export interface SaveLedgerHistoricImportDraftTransfer$Response {
    id: TransferHistoricImportDraftId;
}

export interface SaveStockClass$Request {
    stockClasses: SaveStockClass$Request$StockClassRequest[];
}

export interface SaveStockClass$Request$StockClassRequest {
    firstInOrder: boolean;
    id: StockClassId;
    name: string;
    parValue: number;
    restrictions: string;
    series: SaveStockClass$Request$StockSeriesRequest[];
    totalNumberOfShares: number;
    type: StockType;
    voting: boolean;
    votingRights: number;
}

export interface SaveStockClass$Request$StockSeriesRequest {
    id: StockClassId;
    name: string;
    parValue: number;
    restrictions: string;
    totalNumberOfShares: number;
    voting: boolean;
    votingRights: number;
}

export interface SaveStockClass$Response {
}

export interface SaveTransferRestrictions$Request {
    disableHolderToHolderTransfers: boolean;
}

export interface SaveTransferRestrictions$Response {
}

export interface SaveUserCryptoWallet$Request {
    walletAddress: string;
}

export interface SaveUserCryptoWallet$Response {
}

export interface SaveUserOnboardingDetails$Request {
    address: AddressRequest;
    apartment: string;
    dateOfBirth: string;
    documents: SaveUserOnboardingDetails$Request$UploadedDocumentRequest[];
    givenNames: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    ssn: string;
    streetAddress: string;
}

export interface SaveUserOnboardingDetails$Request$UploadedDocumentRequest {
    token: string;
}

export interface SaveUserOnboardingDetails$Response {
}

export interface SearchAccount$Request {
    searchText: string;
}

export interface SearchAccount$Response {
    result: AccountDto[];
}

export interface SearchAccountWithStock$Request {
    searchText: string;
}

export interface SearchAccountWithStock$Response {
    result: AccountDto[];
}

export interface SearchDraftAccount$Request {
    searchText: string;
}

export interface SearchDraftAccount$Response {
    result: AccountDto[];
}

export interface SelectProfile$Request {
    id: ProfileId;
    role: ProfileRole;
}

export interface SelectProfile$Response {
}

export interface SendAppDownloadInfo$Request {
}

export interface SendAppDownloadInfo$Response {
}

export type SensitiveString = string;

export type SignActionId = string;

export type SignActionVerificationCode = string;

export interface SignLedgerContract$Request {
    contractId: ContractId;
}

export interface SignLedgerContract$Response {
    signActionId: SignActionId;
}

export interface StartRecoverBySms$Request {
}

export interface StartRecoverBySms$Response {
}

export interface StartResetPassword$Request {
    dateOfBirth: string;
    value: string;
}

export interface StartResetPassword$Response {
}

export interface StockClassDto {
    active: boolean;
    createdBy: UserId;
    firstInOrder: boolean;
    id: StockClassId;
    ledgerId: LedgerId;
    parValue: number;
    parentId: StockClassId;
    restrictions: string;
    stockName: string;
    totalNumberOfShares: number;
    type: StockType;
    voting: boolean;
    votingRights: number;
}

export type StockClassId = string;

export interface StockListDto {
    active: boolean;
    createdBy: string;
    firstInOrder: boolean;
    id: StockClassId;
    parValue: number;
    reasons: StockListDto$Reason[];
    restrictions: string;
    series: StockSeriesListDto[];
    stockName: string;
    totalNumberOfShares: number;
    totals: StockListDto$StockClassTotalsDto;
    type: StockType;
    voting: boolean;
    votingRights: number;
}

export interface StockListDto$Reason {
    createdAt: number;
    createdBy: string;
    reason: string;
}

export interface StockListDto$StockClassTotalsDto {
    authorized: number;
    issued: number;
    outstanding: number;
    treasury: number;
    unissued: number;
}

export interface StockSeriesListDto {
    id: StockClassId;
    parValue: number;
    parentId: StockClassId;
    reasons: StockSeriesListDto$Reason[];
    restrictions: string;
    stockName: string;
    totalNumberOfShares: number;
    totals: StockSeriesListDto$StockSeriesTotalsDto;
    voting: boolean;
    votingRights: number;
}

export interface StockSeriesListDto$Reason {
    createdAt: number;
    createdBy: string;
    reason: string;
}

export interface StockSeriesListDto$StockSeriesTotalsDto {
    authorized: number;
    issued: number;
    outstanding: number;
    treasury: number;
    unissued: number;
}

export enum StockType {
    CAPITAL = 'CAPITAL',
    COMMON = 'COMMON',
    OTHER = 'OTHER',
    PREFERRED = 'PREFERRED',
}

export type TransferHistoricImportDraftId = string;

export type TransferId = string;

export interface TransferListDto {
    amountPaid: number;
    certificatedTransfer: string;
    createdAt: number;
    currency: string;
    currencyType: CurrencyType;
    from: TransferListDto$Participant;
    fromAccountName: string;
    id: TransferId;
    issuance: boolean;
    numberOfShares: number;
    pendingIssuance: boolean;
    pendingTransfer: boolean;
    status: TransferStatusType;
    stockClassName: string;
    to: TransferListDto$Participant;
    toAccountName: string;
}

export interface TransferListDto$Participant {
    emails: string[];
    name: string;
    phoneNumbers: string[];
}

export enum TransferStatusType {
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    CREATED = 'CREATED',
    DECLINED = 'DECLINED',
    EXPIRED = 'EXPIRED',
    PENDING = 'PENDING',
    WAITING_FOR_PAYMENT_CONFIRMATION = 'WAITING_FOR_PAYMENT_CONFIRMATION',
}

export interface UpdateLedgerDetails$Request {
    documents: UpdateLedgerDetails$Request$UploadedDocumentRequest[];
    name: string;
    officeAddress: AddressRequest;
    registrationDate: string;
    registrationNumber: string;
    type: LedgerCompanyType;
}

export interface UpdateLedgerDetails$Request$UploadedDocumentRequest {
    token: string;
}

export interface UpdateLedgerDetails$Response {
}

export interface UpdateLegalEntityCompanyDetails$Request {
    address: AddressRequest;
    apartment: string;
    name: string;
    streetAddress: string;
    tin: string;
}

export interface UpdateLegalEntityCompanyDetails$Response {
}

export interface UpdateLegalEntityHolderDetails$Request {
    address: AddressRequest;
    apartment: string;
    email: string;
    holderId: HolderId;
    name: string;
    streetAddress: string;
    tin: string;
}

export interface UpdateLegalEntityHolderDetails$Response {
}

export interface UpdateNaturalPersonHolderDetails$Request {
    address: AddressRequest;
    apartment: string;
    email: string;
    givenNames: string;
    holderId: HolderId;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    ssn: string;
    streetAddress: string;
}

export interface UpdateNaturalPersonHolderDetails$Response {
}

export interface UpdateStockClass$Request {
    documents: UpdateStockClass$Request$UploadedDocumentRequest[];
    id: StockClassId;
    name: string;
    parValue: number;
    reason: string;
    restrictions: string;
    totalNumberOfShares: number;
    type: StockType;
    votesPerShare: number;
    voting: boolean;
}

export interface UpdateStockClass$Request$UploadedDocumentRequest {
    token: string;
}

export interface UpdateStockClass$Response {
    signActionId: SignActionId;
}

export interface UpdateStockClassDetails$Request {
    id: StockClassId;
    name: string;
    parValue: number;
    restrictions: string;
    totalNumberOfShares: number;
    voting: boolean;
    votingRights: number;
}

export interface UpdateStockClassDetails$Response {
}

export interface UpdateStockSeries$Request {
    documents: UpdateStockSeries$Request$UploadedDocumentRequest[];
    id: StockClassId;
    name: string;
    parValue: number;
    reason: string;
    restrictions: string;
    totalNumberOfShares: number;
    votesPerShare: number;
    voting: boolean;
}

export interface UpdateStockSeries$Request$UploadedDocumentRequest {
    token: string;
}

export interface UpdateStockSeries$Response {
    signActionId: SignActionId;
}

export interface UpdateUserDetails$Request {
    address: AddressRequest;
    apartment: string;
    dateOfBirth: string;
    documents: UpdateUserDetails$Request$UploadedDocumentRequest[];
    givenNames: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    ssn: string;
    streetAddress: string;
}

export interface UpdateUserDetails$Request$UploadedDocumentRequest {
    token: string;
}

export interface UpdateUserDetails$Response {
}

export interface UploadedDocumentDto {
    createdAt: number;
    fileName: string;
    token: string;
}

export type UserId = string;

export interface ValidatePhone$Request {
    phone: string;
}

export interface ValidatePhone$Response {
    result: boolean;
}

export interface VerifyPhoneNumber$Request {
    code: string;
}

export interface VerifyPhoneNumber$Response {
}

export interface VerifyWithSecondaryMethod$Request {
    id: CredentialId;
    value: string;
}

export interface VerifyWithSecondaryMethod$Response {
}

export interface WhoAmI$Request {
}

export interface WhoAmI$Response {
    authenticated: boolean;
    currentProfile: WhoAmI$Response$CurrentProfile;
    givenNames: string;
    hasProfiles: boolean;
    lastLoginAt: number;
    lastName: string;
    ledgerCreation: boolean;
    middleName: string;
    nameSuffix: string;
    userId: UserId;
}

export interface WhoAmI$Response$CurrentHolder {
    legalEntity: boolean;
    name: string;
}

export interface WhoAmI$Response$CurrentProfile {
    holder: WhoAmI$Response$CurrentHolder;
    id: ProfileId;
    name: string;
    role: ProfileRole;
}